<template>
  <v-footer
    :fixed="fixed"
    ref="footer"
    min-height="360"
    color="#004651"
    dark
    padless
    style="margin-top: 100px;"
  >
    <!-- <v-row  class="ma-0 mt-16 pa-0" style="background-color: ;"> -->
    <v-row justify="center">
      <v-col cols="12">
        <v-container>
          <v-row justify="center"> 
            <v-col cols="12" lg="9">
              <b class="d-inline-block mb-4">Legal</b><br />
              <a
                href="https://covid-19.hscni.net/vaccine-service-privacy-notice"
                target="_blank"
                style="color: white;"
                >Privacy Notice</a
              >
              <br /><a
                href="/cookies"
                target="_blank"
                style="color: white;"
                >Cookies</a
              >
            </v-col>
            <v-col cols="12" lg="3">
              <b class="d-inline-block mb-4">Translate</b><br />
              <div id="google_translate_element"></div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style>
  .goog-te-gadget, .goog-logo-link {
    color: white !important;
  }
  .goog-te-combo {
    margin-bottom: 10px !important;
    padding: 5px 10px;
    border: 1px solid white;
    color: white;
  }

  .goog-te-combo:hover {
    color:rgb(0, 195, 255);
  }
  .goog-te-combo option {
    color: black;
  }
</style>

<script>
export default {
  name: 'PageFooter',
  props: ['fixed']
};
</script>
